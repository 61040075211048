<template>
  <el-dialog
    title="选择描述"
    :visible.sync="show"
    width="800px"
    top="5vh"
    append-to-body
    :before-close="handleClose"
  >
    <div>
      <div class="flex-between">
        <el-select
          v-model="categoryId"
          placeholder="请先选择分类"
          style="width: 180px"
          filterable
          @change="handlePageChange(1)"
        >
          <el-option
            v-for="item in classifyList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <div>
          <el-input
            placeholder="请输入描述"
            v-model="description"
            class="input-with-select"
            @keydown.enter.native="handlePageChange(1)"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handlePageChange(1)"
            ></el-button>
          </el-input>
          <el-button @click="handleAdd" type="primary" :disabled="!categoryId"
            >添加描述</el-button
          >
        </div>
      </div>

      <el-table
        :data="titleList"

        max-height="580px"
        v-loading="loading"
        highlight-current-row
        @current-change="(row) => (this.currentDescription = row)"
        style="width: 100%; margin: 20px 0"
      >
        <el-table-column prop="select" label="选择" width="50">
          <template slot-scope="scope">
            <el-radio
              type="radio"
              :value="currentDescription ? currentDescription.id : null"
              :label="scope.row.id"
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column property="description" label="描述"> </el-table-column>
      </el-table>
      <el-pagination
        style="text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="15"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <div class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSure">确 定</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getDescriptionList, editDescription } from '../../../api/classify'

export default {
  name: 'selectDescription',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    classifyList: Array
  },
  data () {
    return {
      description: null,
      titleList: [],
      page: 1,
      total: 0,
      loading: false,
      currentDescription: {},
      categoryId: null
    }
  },
  watch: {
    show: {
      handler (newVal) {
        if (newVal) {
          const data = this.classifyList[0]
          this.categoryId = data ? data.id : null
          this.handlePageChange(1)
        }
      },
      immediate: true
    }
  },
  methods: {
    handleAdd () {
      this.$prompt('请输入描述', '新增', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputType: 'text'
      })
        .then(({ value }) => {
          if (value) {
            editDescription({
              description: value,
              categoryId: this.categoryId
            }).then(() => {
              this.handlePageChange(1)
              this.$message.success('保存成功')
            })
          }
        })
        .catch(() => {})
    },
    handleSure () {
      this.$emit('success', this.currentDescription.description)
      this.handleClose()
    },
    handlePageChange (page) {
      this.page = page
      this.getList()
    },
    getList () {
      if (this.categoryId) {
        this.loading = true
        getDescriptionList({
          page: this.page,
          pageSize: 20,
          desc: this.description,
          categoryId: this.categoryId
        })
          .then((res) => {
            this.titleList = res.list
            this.total = res.total
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleClose () {
      this.page = 1
      this.title = ''
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.input-with-select {
  width: 200px;
  margin-right: 20px;
}
.dialog-footer {
  margin-top: 40px;
  text-align: center;
}
</style>
