<template>
  <el-dialog
    :title="handleTitle()"
    :visible.sync="show"
    :fullscreen="true"
    center
    :before-close="handleClose"
  >
    <el-row :gutter="20">
      <el-form
        label-position="left"
        label-width="120px"
        class="form-containers"
      >
        <!-- 表单顶部 -->
        <div class="form-top">
          <el-form-item label="公众号：" class="necessary">
            <el-select
              style="width: 100%"
              v-model="selectChannel"
              value-key="id"
              filterable
              placeholder="公众号"
              :disabled="!!info"
            >
              <el-option
                v-for="item in account"
                :key="item.id"
                :value="item"
                :label="item.channelName"
              >
                <span style="float: left">{{ item.channelName }}</span>
                <span style="float: right; margin-left: 15px; font-size: 13px"
                  >{{ item.platformStr }} - {{ item.uname }}</span
                >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关键字" class="necessary">
            <el-input
              v-model.trim="form.keyWord"
              placeholder="请输入关键字"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input
              v-model="form.remark"
              placeholder="给这条消息起个名字便于查询"
            ></el-input>
          </el-form-item>
        </div>
        <!-- 表单中部 -->
        <div class="form-body">
          <div class="form-body-left">
            <el-form-item label="消息类型：">
              <el-radio-group v-model="form.msgType" size="medium">
                <el-radio-button label="text">文本</el-radio-button>
                <el-radio-button label="news">图文</el-radio-button>
                <el-radio-button label="voice">音频</el-radio-button>
                <el-radio-button label="image">图片</el-radio-button>
                <el-radio-button label="miniprogrampage"
                  >小程序卡片</el-radio-button
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="消息内容："
              class="necessary"
              style="margin-bottom: 14px"
            >
              <template v-if="form.msgType === 'text'">
                <div class="message-box">
                  <el-input
                    type="textarea"
                    @blur="handleBlur"
                    style="width: 700px; height: 400px"
                    :autosize="{ minRows: 18 }"
                    placeholder="请输入内容"
                    v-model="textStr"
                  >
                  </el-input>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;
                  "
                >
                  <el-button
                    class="button-mini"
                    type="text"
                    style="margin-right: 10px"
                    @click="(showTitleDialog = true), (insertInfoType = 1)"
                    >插入标题</el-button
                  >
                  <insert-book
                    :channel="selectChannel"
                    @success="handleSelectSuccess"
                  />
                  <insert-page
                    :channel="selectChannel"
                    @success="handleSelectSuccess"
                  />

                  <el-button
                    class="button-mini"
                    type="text"
                    @click="handleInsert('{$nickname}')"
                    style="margin-right: 10px"
                    >插入用户昵称</el-button
                  >
                  <insert-activity
                    :channel="selectChannel"
                    :type="form.msgType"
                    @success="handleActivityInsert"
                  />
                  <el-button
                    class="button-mini"
                    type="text"
                    @click="showMiniDialog = true"
                    >插入小程序</el-button
                  >
                  <el-button
                    class="button-mini"
                    type="text"
                    @click="showTemplate = true"
                    >插入模板</el-button
                  >
                  <el-button
                    class="button-mini"
                    type="text"
                    @click="checkCoinStatus"
                    v-if="[0, 1, 3, 6, 7].includes(selectChannel.platform)"
                    >插入书币</el-button
                  >
                </div>
              </template>
              <el-form v-if="form.msgType === 'news'" label-width="50px">
                <el-form-item label="链接">
                  <span
                    style="margin-right: 10px; display: block"
                    v-if="form.urlName"
                    >{{ form.urlName }}</span
                  >
                  <insert-book
                    :channel="selectChannel"
                    @success="handleSelectSuccess"
                  />
                  <insert-page
                    :channel="selectChannel"
                    @success="handleSelectSuccess"
                  />
                  <insert-activity
                    :channel="selectChannel"
                    :type="form.msgType"
                    @success="handleActivityInsert"
                  />
                  <template
                    v-if="[0, 1, 3, 6, 7].includes(selectChannel.platform)"
                  >
                    <el-button
                      type="text"
                      size="mini"
                      class="button-mini"
                      @click="checkCoinStatus"
                      >插入书币</el-button
                    >
                  </template>
                </el-form-item>
                <el-form-item label="标题">
                  <el-input
                    v-model="newsForm.title"
                    type="textarea"
                    style="width: 300px; margin-right: 10px"
                  />
                  <el-button
                    type="text"
                    @click="(showTitleDialog = true), (insertInfoType = 2)"
                    >选择标题</el-button
                  >
                </el-form-item>
                <el-form-item label="图片">
                  <div
                    @click="showPicDialog = true"
                    v-if="!newsForm.picurl"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-size: 30px;
                      width: 100px;
                      height: 100px;
                      margin-right: 10px;
                      border: 1px solid #dcdfe6;
                      border-radius: 4px;
                      cursor: pointer;
                      object-fit: contain;
                    "
                  >
                    <!-- <img
                      src="../../assets/empty.png"
                      style="display:block;300px; height: 100px;margin:auto"
                    /> -->
                    <div><i class="el-icon-plus"></i></div>
                  </div>

                  <img
                    @click="showPicDialog = true"
                    v-if="newsForm.picurl"
                    :src="newsForm.picurl"
                    style="
                      width: 100px;
                      height: 100px;
                      margin-right: 10px;
                      cursor: pointer;
                      object-fit: contain;
                    "
                  />
                  <!-- <el-button type="text" @click="showPicDialog = true"
                    >选择图片</el-button
                  > -->
                </el-form-item>
                <el-form-item label="描述">
                  <el-input
                    type="textarea"
                    v-model="newsForm.description"
                    style="width: 300px; margin-right: 10px"
                  />
                  <el-button type="text" @click="showDescDialog = true"
                    >选择描述</el-button
                  >
                </el-form-item>
                <el-form-item>
                  <div class="example">
                    <div class="card">
                      <p class="mesg-title">
                        {{ newsForm.title || "消息标题" }}
                      </p>
                      <div class="detail">
                        <p>{{ newsForm.description || "描述文字" }}</p>
                        <template>
                          <img
                            :src="newsForm.picurl"
                            alt=""
                            v-if="newsForm.picurl"
                          />
                          <div class="image-slot" v-else>
                            <i class="el-icon-picture-outline"></i>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
              <div v-if="form.msgType === 'voice'">
                <span>{{
                  selectVoice ? selectVoice.title || selectVoice.mediaId : ""
                }}</span>
                <el-button
                  type="text"
                  @click="handleDialogShow('showVoiceDialog')"
                  >选择音频素材</el-button
                >
              </div>
              <div v-if="form.msgType === 'image'">
                <!-- <span>{{
                  selectImage ? selectImage.title || selectImage.mediaId : ""
                }}</span> -->
                <div
                  class="image-text-box"
                  @click="handleDialogShow('showImageDialog')"
                  style="margin-right: 10px"
                >
                  <div class="material-item" v-if="selectImage">
                    <p>{{ selectImage.title || selectImage.mediaId }}</p>
                    <img :src="selectImage.url" alt="" />
                  </div>
                  <p v-if="!selectImage"><i class="el-icon-plus"></i></p>
                </div>
                <!-- <el-button
                  type="text"
                  @click="handleDialogShow('showImageDialog')"
                  >选择图片素材</el-button
                > -->
              </div>
              <div v-if="form.msgType === 'miniprogrampage'">
                <input
                  type="file"
                  ref="uploadFile"
                  style="display: none"
                  accept="image/*"
                  @change="handleFileChange"
                />
                <el-form-item
                  v-loading="miniprogramLoading"
                  label="小程序封面"
                  class="necessary"
                >
                  <div class="selectMiniPicContainer">
                    <div class="changeMiniPicType">
                      <el-radio v-model="selectMiniPicType" :label="1"
                        >选择素材</el-radio
                      >
                      <el-radio
                        style="margin-left: 0"
                        v-model="selectMiniPicType"
                        :label="2"
                        >本地上传</el-radio
                      >
                    </div>
                    <div
                      class="selectMiniPicBox"
                      v-loading="selectMiniImageLoading"
                    >
                      <div
                        @click="handleChangeMiniPic"
                        v-if="!minForm.picurl"
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          font-size: 30px;
                          width: 200px;
                          height: 100px;
                          margin-right: 10px;
                          border: 1px solid #dcdfe6;
                          border-radius: 4px;
                          cursor: pointer;
                        "
                      >
                        <div><i class="el-icon-plus"></i></div>
                      </div>
                      <img
                        @click="handleChangeMiniPic"
                        v-if="minForm.picurl"
                        :src="minForm.picurl"
                        style="
                          width: 200px;
                          height: 100px;
                          margin-right: 10px;
                          cursor: pointer;
                        "
                      />
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="标题" class="necessary">
                  <el-input
                    type="text"
                    v-model="minForm.title"
                    style="width: 300px; margin-right: 10px"
                  />
                </el-form-item>
                <el-form-item label="appid" class="necessary">
                  <el-input
                    type="text"
                    v-model="minForm.appid"
                    style="width: 300px; margin-right: 10px"
                  />
                </el-form-item>
                <el-form-item label="页面地址" class="necessary">
                  <el-input
                    type="text"
                    v-model="minForm.pagepath"
                    style="width: 300px; margin-right: 10px"
                  />
                </el-form-item>
              </div>
            </el-form-item>
            <!-- 当天统计信息 -->

            <el-form-item label="测试人" style="margin-bottom: 14px">
              <!--        <el-input v-model="testId" style="width: 350px;margin-right: 10px"/>-->
              <el-select
                v-model="testId"
                filterable
                remote
                reserve-keyword
                placeholder="请输入用户名"
                :remote-method="userQuery"
                :loading="remoteLoading"
                @change="handleTestChange"
              >
                <el-option
                  v-for="item in testUser"
                  :key="item.openId"
                  :label="item.nickname"
                  :value="item.openId"
                >
                  <div class="row">
                    <user-avatar :info="item" name-prop="nickname" :size="25" />
                    <span style="margin-left: 10px">{{ item.nickname }}</span>
                  </div>
                </el-option>
              </el-select>
              <el-button
                style="margin-left: 10px"
                type="primary"
                :loading="loadingButton"
                @click="handleTest"
                >发送</el-button
              >
            </el-form-item>
          </div>
        </div>
      </el-form>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <div class="sub-box">
        <div class="button-box">
          <el-button @click="handleClose">取 消</el-button>
          <el-button
            style="margin-left: 10px"
            type="primary"
            @click="handleSubmit"
            :loading="submitLoading"
            >确定</el-button
          >
        </div>
      </div>
    </span>
    <select-title
      :show="showTitleDialog"
      :classifyList="classifyList"
      :insertInfoType="insertInfoType"
      @close="showTitleDialog = false"
      @success="handleInsertBookTitle"
    />

    <select-image
      :show="showPicDialog"
      :classifyList="classifyList"
      @close="showPicDialog = false"
      @success="handleSelectImageSuccess"
    />

    <select-description
      :show="showDescDialog"
      :classify-list="classifyList"
      @close="showDescDialog = false"
      @success="(description) => (newsForm.description = description)"
    />
    <el-dialog
      title="选择音频素材"
      width="800px"
      :visible.sync="showVoiceDialog"
      :append-to-body="true"
    >
      <select-material
        :show="showVoiceDialog"
        :type="form.msgType"
        @close="showVoiceDialog = false"
        :account-id="selectChannel ? selectChannel.id : null"
        @success="(voice) => (this.selectVoice = voice)"
      />
    </el-dialog>
    <el-dialog
      title="选择图片素材"
      width="800px"
      :visible.sync="showImageDialog"
      :append-to-body="true"
    >
      <s-image
        :show="showImageDialog"
        :type="form.msgType"
        @close="showImageDialog = false"
        :account-id="selectChannel ? selectChannel.id : null"
        @success="(image) => (this.selectImage = image)"
      />
    </el-dialog>
    <el-dialog
      title="插入小程序"
      width="500px"
      :visible.sync="showMiniDialog"
      :append-to-body="true"
    >
      <insert-mini-program
        :show="showMiniDialog"
        @close="showMiniDialog = false"
        @success="handleInsert"
      />
    </el-dialog>
    <InsertTemplate
      :classifyList="classifyList"
      :show="showTemplate"
      @close="showTemplate = false"
      @handleOk="handleInsertTemplate"
    ></InsertTemplate>
    <!-- 发送人详情 -->
    <SendDetailTable
      :params="getNumParams"
      @closeDetail="closeDetail"
      :show="showDetail"
    />
    <!-- 插入书币活动 -->
    <insert-book-coin
      :show="showBookCoin"
      :channel="selectChannel"
      :type="form.msgType"
      @close="showBookCoin = false"
      @success="handleBookCoinInsert"
    ></insert-book-coin>
  </el-dialog>
</template>

<script>
import $ from "jquery";
import jQuery from "jquery";
import area from "@/assets/js/area.js";
import InsertBook from "@/views/service/insertBook/index";
import InsertPage from "@/views/service/insertPage/index";
import InsertActivity from "@/views/service/insertActivity/index";
import SelectTitle from "@/views/service/components/selectTitle";
import SelectImage from "@/views/service/components/selectPic";
import SelectDescription from "@/views/service/components/selectDescription";
import SelectMaterial from "@/views/service/components/selectMaterial";
import InsertMiniProgram from "@/views/service/components/insertMiniProgram";
import InsertTemplate from "@/views/service/components/insertTemplate";
import SImage from "@/views/service/components/selectImage";
import InsertBookCoin from "@/views/service/insertBookCoin";
import { getBookList } from "@/api/account";
import {
  getKeyWord,
  addKeyWord,
  updateKeyWord,
  delKeyWord,
  changeKeyWordStatus,
  copyKeyWord,
} from "@/api/service.js";
import {
  addServiceMessage,
  getEstimateNum,
  testSend,
  getServiceDetail,
  getSendtTagDetatl,
  getDelayMessageDetail,
  getAccountUser,
  editDelayMessage,
  editSendTag,
  platformLocalSelectUpload,
} from "@/api/service";
import { getClassifyList } from "@/api/classify";
import UserAvatar from "@/components/UserAvatar";
import { validActive } from "@/assets/js/utils";
import SendDetailTable from "@/views/service/components/sendDetailTable.vue";
import { messagePickerOptions } from "@/assets/js/options";
import { platformLocalUpload } from "@/api/service";

const defaultForm = {
  msgType: "text",
  sendAll: 1,
  continuousRecharge: 0,
  sex: 0,
  rechargeType: 2,
  isVip: 2,
  rfm: 0,
  couponLevel: 0,
  bookId: null,
  startDate: null,
  endDate: null,
  remark: "",
  urlName: "",
};
export default {
  name: "edit",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    account: {
      type: Array,
      default: () => [],
    },
    info: Object,
    accountId: Number,
    moduleType: Number,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      testId: null,
      str: "",
      estimateNum: null,
      form: {},
      textStr: "", // 文本内容
      textIndex: 0,
      newsForm: {
        picurl: "",
        url: "",
        description: "",
        title: "",
      },
      classifyList: [],
      showTitleDialog: false,
      showPicDialog: false,
      showDescDialog: false,
      showVoiceDialog: false,
      showImageDialog: false,
      showVideoDialog: false,
      showMiniDialog: false,
      showTemplate: false, // 插入模板
      pickerOptions: messagePickerOptions,
      bookList: [],
      loading: false,
      formLoading: false,
      defaultBook: null,
      submitLoading: false,
      selectVoice: null,
      selectImage: null, // 选择图片模块
      selectChannel: null, // 选中公众号
      testUser: [],
      remoteLoading: false,
      insertInfoType: 1, // 判断是在文本还是在图片的消息类型中插入标题(1是文本，2是图片)
      textMaskConten: "",
      timeoutObj: {
        // 针对延迟回复
        hour: 0,
        minute: 0,
        second: 0,
      },
      // 新增客户信息相关
      area,
      areaCity: [],
      // 获取人数参数
      getNumParams: {},
      showDetail: false,
      loadingButton: false,
      // 过滤时长
      subscribeHours: 5,

      showBookCoin: false,
      minForm: {},
      miniprogramLoading: false,
      // 小程序卡片选择改版
      isSelectMiniImage: false,
      selectMiniImageLoading: false,
      selectMiniPicType: 1,
    };
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.minForm = {};
        if (sessionStorage.getItem("testNickName")) {
          this.userQuery(sessionStorage.getItem("testNickName"));
          this.testId = sessionStorage.getItem("testId");
        } else {
          this.testId = null;
        }
        this.estimateNum = null;
        this.userQuery();
        // this.initTextMaskStyle();
        this.initHighlightTextarea();
        if (this.info) {
          this.initData();
        } else {
          if (this.moduleType === 1) {
            this.subscribeHours = 5;
          }
          this.form = { ...defaultForm };

          this.newsForm = {
            picurl: "",
            url: "",
            description: "",
            title: "",
          };
          this.timeoutObj = {
            hour: 0,
            minute: 0,
            second: 0,
          };
          this.textStr = "";
          this.selectVoice = null;
          this.selectImage = null;
          const defaultAccount = this.account.find(
            (item) => item.id === this.accountId
          );
          this.selectChannel = {
            id: this.accountId,
            channelName: defaultAccount ? defaultAccount.channelName : "",
            platform: defaultAccount ? defaultAccount.platform : null,
          };
        }
      } else {
        this.form = {};
        this.textStr = "";
        if ($(".message-box .el-textarea .el-textarea__inner").length > 0) {
          $(
            ".message-box .el-textarea .el-textarea__inner"
          ).highlightWithinTextarea("destory");
        }
      }
    },
    "form.msgType": {
      handler(newV) {
        if (newV === "text") {
          this.initHighlightTextarea();
        }
      },
    },
  },
  mounted() {
    getBookList({ page: 1, pageSize: 50 }).then((res) => {
      this.bookList = res.list;
    });
    getClassifyList({ type: 1, status: 1 }).then((res) => {
      this.classifyList = res;
    });
    this.extendJQuery();
  },
  methods: {
    handleSelectImageSuccess(data) {
      // isSelectMiniImage判定是小程序卡片调用还是其他调用
      if (!this.isSelectMiniImage) {
        this.newsForm.picurl = data;
      } else {
        this.minForm.picurl = data;
        this.selectMiniImageLoading = true;
        platformLocalSelectUpload({
          accountChannelId: this.selectChannel.id,
          url: data,
          type: "thumb",
        })
          .then((res) => {
            this.minForm.thumb_media_id = res.media_id;
          })
          .finally(() => {
            this.selectMiniImageLoading = false;
            this.isSelectMiniImage = false;
          });
      }
    },
    handleChangeMiniPic() {
      if (!this.selectChannel || !this.selectChannel.id) {
        return this.$message.error("请先选择公众号");
      }
      if (this.selectMiniPicType == 2) {
        this.$refs.uploadFile.click();
      } else {
        this.showPicDialog = true;
        this.isSelectMiniImage = true;
      }
    },
    handleFileChange(e) {
      const targetFile = e.target.files[0];
      const file = new FormData();
      file.append("file", targetFile);
      // return;
      this.miniprogramLoading = true;
      platformLocalUpload(this.selectChannel.id, "thumb", file)
        .then((res) => {
          // this.minForm.picurl = res.url;
          this.$set(this.minForm, "picurl", res.url);
          this.$set(this.minForm, "thumb_media_id", res.media_id);
          // this.minForm.thumb_media_id = res.media_id;
        })
        .finally(() => {
          this.miniprogramLoading = false;
        });
    },
    // 缓存发送人
    handleTestChange(item) {
      let nickName = null;
      this.testUser.forEach((element) => {
        if (element.openId === item) {
          nickName = element.nickname;
        }
      });
      sessionStorage.setItem("testNickName", nickName);
      sessionStorage.setItem("testId", item);
    },
    // 关闭详情
    closeDetail() {
      this.showDetail = false;
    },
    handleTitle() {
      return `${this.info ? "编辑" : "新增"}关键字`;
    },
    initHighlightTextarea() {
      this.$nextTick(() => {
        // const regExp = /^(((ht|f)tps?):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/
        setTimeout(() => {
          $("textarea")
            .each(function () {
              this.setAttribute(
                "style",
                "height:" + this.scrollHeight + "px;overflow-y:hidden;"
              );
            })
            .on("input", function () {
              this.style.height = "auto";
              this.style.height = this.scrollHeight + "px";
            });
        }, 50);

        $(
          ".message-box .el-textarea .el-textarea__inner"
        ).highlightWithinTextarea({
          // highlight: /(<a.(href="|href=')(((ht|f)tps?):\/\/)[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])("?|'?)>.[^<\/>)]*<\/a>)/g
          // highlight: /<a.(href="|href=')(((ht|f)tps?):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?>.*<\/a>/g
          highlight:
            /(<a.(href="|href=')(((ht|f)tps?):\/\/)[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])("?|'?)>.*<\/a>)/g,
        });
      });
    },
    userQuery(name) {
      this.remoteLoading = true;
      getAccountUser(this.accountId, name)
        .then((res) => {
          this.testUser = res;
        })
        .finally(() => (this.remoteLoading = false));
    },
    initData() {
      this.formLoading = true;

      const { content, accountChannelId, channelName, urlName } = this.info;
      const defaultAccount = this.account.find(
        (item) => item.id === accountChannelId
      );
      this.selectChannel = {
        id: accountChannelId,
        channelName: channelName,
        platform: defaultAccount ? defaultAccount.platform : null,
      };
      this.form = {
        msgType: this.info.msgType,
        keyWord: this.info.keyWord,
        remark: this.info.remark,
      };

      const contentObj = JSON.parse(content);
      if (this.form.msgType === "text") {
        this.textStr = contentObj.content;
      } else if (this.form.msgType === "news") {
        this.$set(this.form, "urlName", urlName);
        this.newsForm = contentObj.articles[0];
      } else if (this.form.msgType === "voice") {
        this.selectVoice = {
          mediaId: contentObj.media_id,
          title: urlName,
        };
      } else if (this.form.msgType === "image") {
        this.selectImage = {
          mediaId: contentObj.media_id,
          title: urlName.includes("{") ? JSON.parse(urlName).title : urlName,
          url: urlName.includes("{") ? JSON.parse(urlName).url : null,
        };
      } else if (this.form.msgType === "miniprogrampage") {
        this.minForm = {
          picurl: this.info.urlName,
          title: contentObj.title,
          appid: contentObj.appid,
          pagepath: contentObj.pagepath,
          thumb_media_id: contentObj.thumb_media_id,
        };
      }
    },
    handleTest() {
      const { msgType, remark } = this.form;
      const {
        textStr,
        newsForm,
        testId,
        selectVoice,
        selectImage,
        selectChannel,
        key,
      } = this;

      if (!selectChannel) {
        this.$message.error("请选择公众号");
        return false;
      }
      if (!this.form.keyWord) {
        return this.$message.error("请输入关键字");
      }
      if (msgType === "text" && !textStr) {
        this.$message.error("请输入发送内容");
        return false;
      }
      if (msgType === "voice" && !selectVoice) {
        this.$message.error("请选择音频素材");
        return false;
      }
      if (msgType === "image" && !selectImage) {
        this.$message.error("请选择图片素材");
        return false;
      }
      if (msgType === "news" && !newsForm.url) {
        this.$message.error("请选择图文链接");
        return false;
      }
      // 小程序卡片
      if (msgType === "miniprogrampage") {
        if (!this.minForm.thumb_media_id) {
          return this.$message.error("请选择小程序封面");
        }
        if (!this.minForm.title) {
          return this.$message.error("请填写标题");
        }
        if (!this.minForm.appid) {
          return this.$message.error("请填写appid");
        }
        if (!this.minForm.pagepath) {
          return this.$message.error("请填写pagepath");
        }
      }
      //   if (!remark) {
      //     this.$message.error("请输入备注");
      //     return false;
      //   }
      const params = {
        accountChannelId: selectChannel.id,
        msgType,
        openId: testId,
        remark,
      };
      if (msgType === "text") {
        params.content = JSON.stringify({ content: textStr });
      } else if (msgType === "news") {
        params.content = JSON.stringify({
          articles: [
            {
              picurl: newsForm.picurl,
              url: newsForm.url,
              description: newsForm.description,
              title: newsForm.title,
            },
          ],
        });
      } else if (msgType === "voice") {
        params.content = JSON.stringify({
          media_id: selectVoice.mediaId,
        });
        params.urlName = selectVoice.title;
      } else if (msgType === "image") {
        params.content = JSON.stringify({
          media_id: selectImage.mediaId,
        });
        params.urlName = selectImage.title;
      } else if (msgType === "miniprogrampage") {
        params.content = JSON.stringify({
          title: this.minForm.title,
          appid: this.minForm.appid,
          pagepath: this.minForm.pagepath,
          thumb_media_id: this.minForm.thumb_media_id,
        });
        params.urlName = this.minForm.picurl;
      }

      this.loadingButton = true;
      testSend(params)
        .then(() => {
          this.$message.success("发送成功");
        })
        .finally(() => {
          this.loadingButton = false;
        });
    },
    getNum() {
      if (this.selectChannel) {
        const params = {
          accountChannelId: this.selectChannel.id,
          sendAll: this.form.sendAll,
          minSendNum: this.form.minSendNum,
          maxSendNum: this.form.maxSendNum,
        };
        if (this.moduleType === 1) {
          params.subscribeHours = this.subscribeHours; //新增排除时长
        }
        if (this.form.sendAll === 0) {
          params.rechargeType = this.form.rechargeType;
          params.bookId = this.form.bookId;
          params.sex = this.form.sex;
          params.startDate = this.form.startDate;
          params.endDate = this.form.endDate;
          params.rechargeTimeSpaceStart = this.form.rechargeTimeSpaceStart;
          params.rechargeTimeSpaceEnd = this.form.rechargeTimeSpaceEnd;
          params.minTotalRecharge = this.form.minTotalRecharge;
          params.maxTotalRecharge = this.form.maxTotalRecharge;
          params.minRechargeCount = this.form.minRechargeCount;
          params.maxRechargeCount = this.form.maxRechargeCount;
          // 新增城市选择
          params.province = this.form.province;
          params.city = this.form.city;
          params.continuousRecharge = this.form.continuousRecharge;
          params.maxSevenRecharge = this.form.maxSevenRecharge;
          params.minSevenRecharge = this.form.minSevenRecharge;
          params.minSendNum = this.form.minSendNum;
          params.maxSendNum = this.form.maxSendNum;
          params.isVip = this.form.isVip;
          params.rfm = this.form.rfm;
          params.couponLevel = this.form.couponLevel;
        }
        if (
          (this.selectChannel.platform === 3 ||
            this.selectChannel.platform === 0) &&
          this.moduleType === 1 &&
          this.form.rechargeType === 2
        ) {
          params.minKanDian = this.form.minKanDian;
          params.maxKanDian = this.form.maxKanDian;
        }
        this.getNumParams = params;
        getEstimateNum(params).then((res) => {
          this.estimateNum = typeof res === "number" ? res : 0;
        });
      } else {
        this.$message.error("请选择公众号");
      }
    },
    handleSubmit() {
      const {
        textStr,
        newsForm,
        form,
        info,
        selectVoice,
        selectImage,
        selectChannel,
      } = this;
      const { msgType, tick } = form;
      if (!selectChannel) {
        this.$message.error("请选择公众号");
        return false;
      }
      if (!this.form.keyWord) {
        return this.$message.error("请输入关键字!");
      }
      if (msgType === "text" && !textStr) {
        this.$message.error("请输入发送内容");
        return false;
      }
      if (msgType === "voice" && !selectVoice) {
        this.$message.error("请选择音频素材");
        return false;
      }
      if (msgType === "image" && !selectImage) {
        this.$message.error("请选择图片素材");
      }
      if (msgType === "news" && !newsForm.url) {
        this.$message.error("请选择图文链接");
        return false;
      }
      // 小程序卡片
      if (msgType === "miniprogrampage") {
        if (!this.minForm.picurl) {
          return this.$message.error("请选择小程序封面");
        }
        if (!this.minForm.title) {
          return this.$message.error("请填写标题");
        }
        if (!this.minForm.appid) {
          return this.$message.error("请填写appid");
        }
        if (!this.minForm.pagepath) {
          return this.$message.error("请填写页面地址");
        }
      }
      const params = {};
      let content = null;
      if (msgType === "text") {
        params.content = JSON.stringify({ content: textStr });
      } else if (msgType === "news") {
        params.content = JSON.stringify({
          articles: [
            {
              picurl: newsForm.picurl,
              url: newsForm.url,
              description: newsForm.description,
              title: newsForm.title,
            },
          ],
        });
        params.urlName = form.urlName;
      } else if (msgType === "voice") {
        params.content = JSON.stringify({
          media_id: selectVoice.mediaId,
        });
        params.urlName = selectVoice.title;
      } else if (msgType === "image") {
        params.content = JSON.stringify({
          media_id: selectImage.mediaId,
        });
        params.urlName = JSON.stringify(selectImage);
      } else if (msgType === "miniprogrampage") {
        params.content = JSON.stringify({
          title: this.minForm.title,
          appid: this.minForm.appid,
          pagepath: this.minForm.pagepath,
          thumb_media_id: this.minForm.thumb_media_id,
        });
        params.urlName = this.minForm.picurl;
      }
      if (!this.isEdit) {
        addKeyWord({
          accountChannelId: this.selectChannel.id,
          keyWord: this.form.keyWord,
          msgType: this.form.msgType,
          remark: this.form.remark,
          ...params,
        }).then(() => {
          this.$message.success("新增成功!");
          this.handleClose();
          this.$emit("refresh");
        });
      } else {
        updateKeyWord(this.info.id, {
          accountChannelId: this.selectChannel.id,
          keyWord: this.form.keyWord,
          msgType: this.form.msgType,
          remark: this.form.remark,
          ...params,
        }).then(() => {
          this.$message.success("修改成功！");
          this.handleClose();
          this.$emit("refresh");
        });
      }
    },
    getBooks(query) {
      this.defaultBook = null;
      getBookList({ page: 1, pageSize: 50, bookName: query }).then((res) => {
        this.bookList = res.list;
      });
    },
    handleInsertBookTitle(title) {
      this.insertInfoType === 1
        ? this.handleInsert(title)
        : (this.newsForm.title = title);
    },
    handleDialogShow(prop) {
      if (this.selectChannel) {
        this[prop] = true;
      } else {
        this.$message.warning("请先选择公众号！");
      }
    },
    // 对文本当中的html进行转义
    HTMLEncode(html) {
      var temp = document.createElement("div");
      temp.textContent != null
        ? (temp.textContent = html)
        : (temp.innerText = html);
      var output = temp.innerHTML;
      temp = null;
      return output;
    },
    handleBlur(event) {
      this.textIndex = event.srcElement.selectionStart;
    },
    handleActivityInsert(data) {
      if (this.form.msgType === "text") {
        this.handleInsert(data.join("\n"));
      } else {
        this.form.urlName = data.name;
        this.newsForm.url = data.url;
      }
    },
    checkCoinStatus() {
      if (!this.selectChannel) return this.$message.error("请选择公众号");
      this.showBookCoin = true;
    },
    handleBookCoinInsert(data) {
      if (this.form.msgType === "text") {
        this.handleInsert(data.value);
      } else {
        this.form.urlName = data.name;
        this.newsForm.url = data.url;
      }
    },
    handleSelectSuccess({ link, name, url, bookTitle }) {
      if (this.form.msgType === "text") {
        this.handleInsert(link);
      } else {
        this.form.urlName = bookTitle ? `${bookTitle}${name}` : `${name}`;
        this.newsForm.url = url;
      }
    },
    handleInsert(str) {
      const { textStr, textIndex } = this;
      this.textStr =
        textStr.slice(0, textIndex) + str + textStr.slice(textIndex);
    },
    handleClose() {
      this.$emit("close");
    },
    // ! 插入模板内容
    handleInsertTemplate(val) {
      const { content } = val;
      this.handleInsert(content);
    },
    extendJQuery() {
      (function ($) {
        const ID = "hwt";

        const HighlightWithinTextarea = function ($el, config) {
          this.init($el, config);
        };

        HighlightWithinTextarea.prototype = {
          init: function ($el, config) {
            this.$el = $el;

            // backwards compatibility with v1 (deprecated)
            if (this.getType(config) === "function") {
              config = { highlight: config };
            }

            if (this.getType(config) === "custom") {
              this.highlight = config;
              this.generate();
            } else {
              console.error("valid config object not provided");
            }
          },

          // returns identifier strings that aren't necessarily "real" JavaScript types
          getType: function (instance) {
            const type = typeof instance;
            if (!instance) {
              return "falsey";
            } else if (Array.isArray(instance)) {
              if (
                instance.length === 2 &&
                typeof instance[0] === "number" &&
                typeof instance[1] === "number"
              ) {
                return "range";
              } else {
                return "array";
              }
            } else if (type === "object") {
              if (instance instanceof RegExp) {
                return "regexp";
                // } else if (instance.hasOwnProperty('highlight')) {
              } else if (
                Object.prototype.hasOwnProperty.call(instance, "highlight")
              ) {
                return "custom";
              }
            } else if (type === "function" || type === "string") {
              return type;
            }

            return "other";
          },

          generate: function () {
            this.$el
              .addClass(ID + "-input " + ID + "-content")
              .on("input." + ID, this.handleInput.bind(this))
              .on("scroll." + ID, this.handleScroll.bind(this));

            this.$highlights = $("<div>", {
              class: ID + "-highlights " + ID + "-content",
            });

            this.$backdrop = $("<div>", { class: ID + "-backdrop" }).append(
              this.$highlights
            );

            this.$container = $("<div>", { class: ID + "-container" })
              .insertAfter(this.$el)
              .append(this.$backdrop, this.$el) // moves $el into $container
              .on("scroll", this.blockContainerScroll.bind(this));

            this.browser = this.detectBrowser();
            switch (this.browser) {
              case "firefox":
                this.fixFirefox();
                break;
              case "ios":
                this.fixIOS();
                break;
            }

            // plugin function checks this for success
            this.isGenerated = true;

            // trigger input event to highlight any existing input
            this.handleInput();
          },

          // browser sniffing sucks, but there are browser-specific quirks to handle
          // that are not a matter of feature detection
          detectBrowser: function () {
            const ua = window.navigator.userAgent.toLowerCase();
            if (ua.indexOf("firefox") !== -1) {
              return "firefox";
            } else if (ua.match(/msie|trident\/7|edge/)) {
              return "ie";
            } else if (
              !!ua.match(/ipad|iphone|ipod/) &&
              ua.indexOf("windows phone") === -1
            ) {
              // Windows Phone flags itself as "like iPhone", thus the extra check
              return "ios";
            } else {
              return "other";
            }
          },

          // Firefox doesn't show text that scrolls into the padding of a textarea, so
          // rearrange a couple box models to make highlights behave the same way
          fixFirefox: function () {
            // take padding and border pixels from highlights div
            const padding = this.$highlights.css([
              "padding-top",
              "padding-right",
              "padding-bottom",
              "padding-left",
            ]);
            const border = this.$highlights.css([
              "border-top-width",
              "border-right-width",
              "border-bottom-width",
              "border-left-width",
            ]);
            this.$highlights.css({
              padding: "0",
              "border-width": "0",
            });

            this.$backdrop
              .css({
                // give padding pixels to backdrop div
                "margin-top": "+=" + padding["padding-top"],
                "margin-right": "+=" + padding["padding-right"],
                "margin-bottom": "+=" + padding["padding-bottom"],
                "margin-left": "+=" + padding["padding-left"],
              })
              .css({
                // give border pixels to backdrop div
                "margin-top": "+=" + border["border-top-width"],
                "margin-right": "+=" + border["border-right-width"],
                "margin-bottom": "+=" + border["border-bottom-width"],
                "margin-left": "+=" + border["border-left-width"],
              });
          },

          // iOS adds 3px of (unremovable) padding to the left and right of a textarea,
          // so adjust highlights div to match
          fixIOS: function () {
            this.$highlights.css({
              "padding-left": "+=3px",
              "padding-right": "+=3px",
            });
          },

          handleInput: function () {
            const input = this.$el.val();
            const ranges = this.getRanges(input, this.highlight);
            const unstaggeredRanges = this.removeStaggeredRanges(ranges);
            const boundaries = this.getBoundaries(unstaggeredRanges);
            this.renderMarks(boundaries);
          },

          getRanges: function (input, highlight) {
            const type = this.getType(highlight);
            switch (type) {
              case "array":
                return this.getArrayRanges(input, highlight);
              case "function":
                return this.getFunctionRanges(input, highlight);
              case "regexp":
                return this.getRegExpRanges(input, highlight);
              case "string":
                return this.getStringRanges(input, highlight);
              case "range":
                return this.getRangeRanges(input, highlight);
              case "custom":
                return this.getCustomRanges(input, highlight);
              default:
                if (!highlight) {
                  // do nothing for falsey values
                  return [];
                } else {
                  console.error("unrecognized highlight type");
                }
            }
          },

          getArrayRanges: function (input, arr) {
            const ranges = arr.map(this.getRanges.bind(this, input));
            return Array.prototype.concat.apply([], ranges);
          },

          getFunctionRanges: function (input, func) {
            return this.getRanges(input, func(input));
          },

          getRegExpRanges: function (input, regex) {
            const ranges = [];
            let match;
            while (((match = regex.exec(input)), match !== null)) {
              ranges.push([match.index, match.index + match[0].length]);
              if (!regex.global) {
                // non-global regexes do not increase lastIndex, causing an infinite loop,
                // but we can just break manually after the first match
                break;
              }
            }
            return ranges;
          },

          getStringRanges: function (input, str) {
            const ranges = [];
            const inputLower = input.toLowerCase();
            const strLower = str.toLowerCase();
            let index = 0;
            while (
              ((index = inputLower.indexOf(strLower, index)), index !== -1)
            ) {
              ranges.push([index, index + strLower.length]);
              index += strLower.length;
            }
            return ranges;
          },

          getRangeRanges: function (input, range) {
            return [range];
          },

          getCustomRanges: function (input, custom) {
            const ranges = this.getRanges(input, custom.highlight);
            if (custom.className) {
              ranges.forEach(function (range) {
                // persist class name as a property of the array
                if (range.className) {
                  range.className = custom.className + " " + range.className;
                } else {
                  range.className = custom.className;
                }
              });
            }
            return ranges;
          },

          // prevent staggered overlaps (clean nesting is fine)
          removeStaggeredRanges: function (ranges) {
            const unstaggeredRanges = [];
            ranges.forEach(function (range) {
              const isStaggered = unstaggeredRanges.some(function (
                unstaggeredRange
              ) {
                const isStartInside =
                  range[0] > unstaggeredRange[0] &&
                  range[0] < unstaggeredRange[1];
                const isStopInside =
                  range[1] > unstaggeredRange[0] &&
                  range[1] < unstaggeredRange[1];
                return isStartInside !== isStopInside; // xor
              });
              if (!isStaggered) {
                unstaggeredRanges.push(range);
              }
            });
            return unstaggeredRanges;
          },

          getBoundaries: function (ranges) {
            const boundaries = [];
            ranges.forEach(function (range) {
              boundaries.push({
                type: "start",
                index: range[0],
                className: range.className,
              });
              boundaries.push({
                type: "stop",
                index: range[1],
              });
            });

            this.sortBoundaries(boundaries);
            return boundaries;
          },

          sortBoundaries: function (boundaries) {
            // backwards sort (since marks are inserted right to left)
            boundaries.sort(function (a, b) {
              if (a.index !== b.index) {
                return b.index - a.index;
              } else if (a.type === "stop" && b.type === "start") {
                return 1;
              } else if (a.type === "start" && b.type === "stop") {
                return -1;
              } else {
                return 0;
              }
            });
          },

          renderMarks: function (boundaries) {
            let input = this.$el.val();
            boundaries.forEach(function (boundary, index) {
              let markup;
              if (boundary.type === "start") {
                markup = "{{hwt-mark-start|" + index + "}}";
              } else {
                markup = "{{hwt-mark-stop}}";
              }
              input =
                input.slice(0, boundary.index) +
                markup +
                input.slice(boundary.index);
            });

            // this keeps scrolling aligned when input ends with a newline
            input = input.replace(/\n(\{\{hwt-mark-stop\}\})?$/, "\n\n$1");

            // encode HTML entities
            input = input.replace(/</g, "&lt;").replace(/>/g, "&gt;");

            if (this.browser === "ie") {
              // IE/Edge wraps whitespace differently in a div vs textarea, this fixes it
              input = input.replace(/ /g, " <wbr>");
            }

            // replace start tokens with opening <mark> tags with class name
            input = input.replace(
              /\{\{hwt-mark-start\|(\d+)\}\}/g,
              function (match, submatch) {
                var className = boundaries[+submatch].className;
                if (className) {
                  return '<mark class="' + className + '">';
                } else {
                  return "<mark>";
                }
              }
            );

            // replace stop tokens with closing </mark> tags
            input = input.replace(/\{\{hwt-mark-stop\}\}/g, "</mark>");

            this.$highlights.html(input);
          },

          handleScroll: function () {
            const scrollTop = this.$el.scrollTop();
            this.$backdrop.scrollTop(scrollTop);

            // Chrome and Safari won't break long strings of spaces, which can cause
            // horizontal scrolling, this compensates by shifting highlights by the
            // horizontally scrolled amount to keep things aligned
            const scrollLeft = this.$el.scrollLeft();
            this.$backdrop.css(
              "transform",
              scrollLeft > 0 ? "translateX(" + -scrollLeft + "px)" : ""
            );
          },

          // in Chrome, page up/down in the textarea will shift stuff within the
          // container (despite the CSS), this immediately reverts the shift
          blockContainerScroll: function () {
            this.$container.scrollLeft(0);
          },

          destroy: function () {
            this.$backdrop.remove();
            this.$el
              .unwrap()
              .removeClass(ID + "-text " + ID + "-input")
              .off(ID)
              .removeData(ID);
          },
        };

        // register the jQuery plugin
        $.fn.highlightWithinTextarea = function (options) {
          return this.each(function () {
            const $this = $(this);
            let plugin = $this.data(ID);

            if (typeof options === "string") {
              if (plugin) {
                switch (options) {
                  case "update":
                    plugin.handleInput();
                    break;
                  case "destroy":
                    plugin.destroy();
                    break;
                  // default:
                  //   // console.error("unrecognized method string");
                }
              } else {
                console.error("plugin must be instantiated first");
              }
            } else {
              if (plugin) {
                plugin.destroy();
              }
              plugin = new HighlightWithinTextarea($this, options);
              if (plugin.isGenerated) {
                $this.data(ID, plugin);
              }
            }
          });
        };
        // })()
      })(jQuery);
    },
  },
  beforeDestroy() {
    $(".message-box .el-textarea .el-textarea__inner").highlightWithinTextarea(
      "destory"
    );
  },
  components: {
    InsertBook,
    InsertPage,
    SelectTitle,
    // UploadImage,
    SelectImage,
    SelectDescription,
    InsertActivity,
    SelectMaterial,
    InsertMiniProgram,
    UserAvatar,
    InsertTemplate,
    SImage,
    SendDetailTable,
    InsertBookCoin,
  },
};
</script>

<style lang='scss' scoped>
// @import url("./script/highlight.css");
@import url("./script/highlight.css");
.form-containers {
  width: 900px;
  margin: auto;
}
.form-container-left {
  width: 600px;
  margin-left: auto;
}
.form-container-right {
  width: 600px;
  margin-right: auto;
}
/deep/ .el-button {
  margin-right: 0px !important;
  margin-left: 0px;
}
/deep/ .el-drawer__body {
  overflow-y: auto;
}
/deep/ .el-drawer {
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
// /deep/ .el-input-number__decrease{
//   top:1px;
// }
a {
  -webkit-tap-highlight-color: transparent;
}

.example {
  width: 375px;
  background-color: #f1f1f1;
  padding: 10px;
}

.card {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
}

.mesg-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-weight: bold;
  line-height: 20px;
}

.detail {
  display: flex;
  align-items: center;
}

.detail p {
  flex: 1;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;
}

.detail img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: #f5f7fa;
  color: #909399;
}
.message-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-mask {
  border: 1px solid #c0c4cc;
  border-radius: 4px;
  min-height: 259px;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
/* .text-mask > span {
  padding-left: 10px;
  font-size: 12px;
} */

/**
**
**改版客服消息排版相关css
**
**/
// 新增属性
.inner-form {
  width: 480px;
  border: 1px solid #f6f6f6;
  border-radius: 20px;
  padding: 10px;
  background-color: #f6f6f6;
  margin-left: -10px;
}
.form-container /deep/ .el-form-item__label {
  font-size: 13px;
}
.form-container /deep/ .el-radio-button--medium .el-radio-button__inner {
  font-size: 13px;
}
.form-container .el-radio__label {
  font-size: 13px;
}
/deep/ .el-dialog__body {
  padding-bottom: 0 !important;
  padding-top: 10px !important;
}
.el-form-item--small.el-form-item {
  margin-bottom: 10px;
}
.form-top {
  background-color: #fbfbfb;
  padding: 10px 20px 0 20px;
}
/deep/ .necessary > .el-form-item__label {
  position: relative;
}
/deep/ .necessary > .el-form-item__label::before {
  position: absolute;
  top: 0;
  left: -8px;
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
.form-body {
  background-color: #fbfbfb;
  margin-top: 10px;
  padding: 0 20px;
  padding-bottom: 0;
  display: flex;
  .message-box {
    justify-content: flex-start;
  }
  .form-body-left {
    margin-top: 10px;
    width: 100%;
  }

  .form-body-right {
    padding-left: 85px;
    position: relative;
    .form-body-right-mask {
      z-index: 9999;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#ababab, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 16px;
        color: #fff;
      }
    }
    .vip-consume {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .el-radio-group {
        .el-radio {
          margin: 0 5px !important;
        }
      }
      .consume-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        i {
          margin: 0 10px 10px 0;
        }
      }
    }
    .rfm-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      i {
        margin: 0 10px 10px 0 !important;
      }
    }
  }
}
.dialog-footer {
  .test-box {
    width: 520px;
    margin: auto;
    /deep/ .el-form-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .sub-box {
    display: flex;
    width: 520px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    .person-count {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
// 图片选择
.image-text-box {
  cursor: pointer;
  display: block;
  width: 500px;
  height: 400px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 5px 15px;
  background-color: #fff;
  color: #8b8b8b;
  line-height: 16px;
  position: relative;
  > p {
    display: inline-block;
    width: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    text-align: center;
    color: #ccc;
  }
  .material-item {
    width: 300px;
    p {
      padding: 5px;
    }
    img {
      max-width: 300px;
      max-height: 300px;
    }
  }
}
// 小程序图片选择
.selectMiniPicContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .changeMiniPicType {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 20px;
    label {
      margin-right: 0 !important;
      margin-bottom: 10px;
    }
  }
}
</style>
