export let rowData = {
  rowList: [
    {
      label: "id",
      prop: "id",
      width: '70'
    },
    {
      label: "关键字",
      prop: "keyWord"
    },

    {
      label: "消息类型",
      prop: "msgType",
      tag: true
    },
    {
      label: "状态",
      prop: "status",
      switch: true,
      width: '150'
    },
    {
      label: '备注',
      prop: 'remark',
      popover: true,
    },
    {
      label: "创建时间",
      prop: "createdTime"
    },
  ],
  tagInfo: [
    {
      tagName: 'msgType',
      tagVal: [{
        val: 'text',
        text: '文本'
      }, {
        val: 'news',
        text: '图文'
      }, {
        val: 'voice',
        text: '音频'
      }, {
        val: 'image',
        text: '图片'
      }, {
        val: 'miniprogrampage',
        text: '小程序卡片'
      }
      ]
    },
    {
      tagName: 'status',
      tagVal: [{
        val: 0,
        text: '否'
      }, {
        val: 1,
        text: '是'
      }]
    }
  ]
};
