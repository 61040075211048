<template>
  <div
    class="
      key-word-container
      border-radius-6
      box-shadow-light-grey
      padding-20
      height-calc-type1
    "
  >
    <c-table
      height="70vh"
      operationWidth="300"
      :isUseCheckbox="true"
      :total="total"
      :page="page"
      :pageSize="pageSize"
      :dataList="dataList"
      :rowList="rowList"
      :tagInfo="tagInfo"
      :loading="loading"
      :isShowPagination="false"
      @sortChange="sortChange"
      @selectChange="selectChange"
      @pageChange="pageChange"
      @handleEvent="handleEvent"
    >
      <template #filter>
        <el-button @click="initData">刷新数据</el-button>
        <el-button
          type="success"
          style="margin-right: 10px"
          @click="copyItem"
          :disabled="selectList.length === 0"
          >批量复制</el-button
        >
        <el-select
          v-model="status"
          placeholder="是否启用"
          style="margin-right: 10px"
          clearable
          filterable
          @change="initData"
        >
          <el-option label="否" :value="0"></el-option>
          <el-option label="是" :value="1"></el-option>
        </el-select>
        <el-input
          clearable
          v-model="keyWord"
          placeholder="请输入关键字"
          style="width: 200px; margin-right: 10px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="initData"
          ></el-button>
        </el-input>
        <!-- <el-popover placement="top" trigger="hover">
          <div>手动刷新微信后台关键字回复</div>
          <el-button  slot="reference" type='warning' :loading='syncLoading' @click='syncData'> 同步数据</el-button>
        </el-popover> -->
        <el-button
          @click="addItem"
          type="primary"
          style="margin-right: 10px; float: right"
          icon="el-icon-plus"
          >新增关键字</el-button
        >
      </template>
      <template #buttonOperation="{ row }">
        <el-button
          type="info"
          size="primary"
          class="button-small"
          @click="checkDetail(row)"
          >详情</el-button
        >

        <el-button
          type="primary"
          size="small"
          class="button-small"
          @click="editItem(row)"
          >编辑</el-button
        >
        <el-button
          type="success"
          size="small"
          class="button-small"
          @click="copyTarget(row)"
          >复制</el-button
        >
        <el-button
          type="danger"
          size="small"
          class="button-small"
          @click="delItem(row)"
          >删除</el-button
        >
      </template>
    </c-table>
    <add-key-word
      :show="showKeyWordDialog"
      :account-id="accountChannelId"
      :info="current"
      :account="channelList"
      :isEdit="isEdit"
      @refresh="initData()"
      @close="showKeyWordDialog = false"
    ></add-key-word>
    <key-word-detail
      :show="showDetail"
      :info="current"
      @close="showDetail = false"
    ></key-word-detail>
    <el-dialog
      title="复制"
      :visible.sync="showDialog"
      append-to-body
      center
      width="400px"
      v-dialogDrag
    >
      <el-select
        style="width: 350px"
        v-model="selAccountList"
        placeholder="请选择公众号!"
        clearable
        filterable
        multiple
      >
        <el-option
          v-for="item in targetAccList"
          :key="item.id"
          :label="item.channelName"
          :value="item.id"
        >
          <span style="float: left">{{ item.channelName }}</span>
          <span style="float: right; margin-left: 15px; font-size: 13px"
            >{{ item.platformStr }} - {{ item.uname }}</span
          >
        </el-option>
      </el-select>
      <div class="button-list">
        <el-button type="primary" @click="handleOk" :loading="copyLoading"
          >确定</el-button
        >
        <el-button @click="handleCancel">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { rowData } from "./rowData.js";
import { mapGetters } from "vuex";
import {
  getKeyWord,
  addKeyWord,
  updateKeyWord,
  delKeyWord,
  changeKeyWordStatus,
  copyKeyWord,
  syncWXKeyWordData,
} from "@/api/service.js";
import AddKeyWord from "./add-key-word.vue";
import keyWordDetail from "./detail.vue";
import { messagePlatformList } from "@/assets/js/options"; //客服消息支持平台
export default {
  props: ["accountChannelId", "relationId"],
  data() {
    return {
      dataList: [],
      selectList: [],
      rowList: JSON.parse(JSON.stringify(rowData.rowList)),
      total: 0,
      tagInfo: JSON.parse(JSON.stringify(rowData.tagInfo)),
      pageSize: 15,
      loading: false,
      keyWord: "",
      page: 1,
      current: null,
      status: null,
      isEdit: false,
      showKeyWordDialog: false,
      needToChangeStatus: null,
      showDialog: false,
      selAccountList: [],
      id: null,
      copyLoading: false,
      showDetail: false,
      targetAccList: [],
      syncLoading: false,
      messagePlatformList,
    };
  },
  watch: {
    accountChannelId: {
      handler(newVal) {
        if (newVal) {
          this.page = 1;
          this.initData();
        }
        const platform = this.channelList.find(
          (item) => item.id === newVal
        ).platform;
        this.targetAccList = this.channelList.filter(
          (item) => item.platform === platform
        );
      },
      immediate: true,
    },
  },
  computed: {
    channelList() {
      const { officialList } = this;
      // return officialList.filter((item) => item.status === 1)
      return officialList.filter(
        (item) =>
          messagePlatformList.includes(item.platform) && item.status === 1
      );
    },
    ...mapGetters(["officialList"]),
  },
  // mounted() {
  //   this.initData();
  // },
  methods: {
    initData() {
      this.loading = true;
      getKeyWord({
        accountChannelId: this.accountChannelId,
        page: this.page,
        pageSize: this.pageSize,
        keyWord: this.keyWord,
        status: this.status,
      })
        .then((res) => {
          this.dataList = res;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    syncData() {
      this.$confirm("是否同步该公众号的数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.syncLoading = true;
          syncWXKeyWordData(this.accountChannelId)
            .then(() => {
              this.$message.success("同步成功!");
            })
            .finally(() => {
              this.syncLoading = false;
            });
        })
        .catch(() => {});
    },
    sortChange(val) {},
    selectChange(val) {
      this.selectList = val;
    },
    pageChange(val) {
      this.page = val;
      this.initData();
    },
    addItem() {
      this.isEdit = false;
      this.showKeyWordDialog = true;
      this.current = null;
    },
    editItem(row) {
      this.isEdit = true;
      this.showKeyWordDialog = true;
      this.current = row;
    },
    delItem(row) {
      this.$confirm("删除的内容将无法恢复!", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delKeyWord(row.id).then(() => {
            this.$message.success("删除成功!");
            this.initData();
          });
        })
        .catch(() => {});
    },
    checkDetail(row) {
      this.current = row;
      this.showDetail = true;
    },
    handleEvent({ info, val }) {
      changeKeyWordStatus(info.id, (status = val))
        .then(() => {
          this.$message.success("修改成功!");
        })
        .finally(() => {
          this.initData();
        });
    },
    handleOk() {
      if (this.selAccountList.length === 0) {
        return this.$message.warning("请选择公众号!");
      }
      const ids = this.id
        ? this.id
        : this.selectList.map((item) => item.id).join(",");
      this.copyLoading = true;
      copyKeyWord(ids, {
        accountChannelIds: this.selAccountList,
      })
        .then(() => {
          this.$message.success("复制成功!");
          this.showDialog = false;
          this.selAccountList = [];
          this.id = null;
          this.initData();
        })
        .finally(() => {
          this.copyLoading = false;
        });
    },
    copyItem() {
      this.showDialog = true;
    },
    copyTarget(row) {
      this.id = row.id;
      this.showDialog = true;
    },
    handleCancel() {
      this.showDialog = false;
      this.selAccountList = [];
    },
    changeStatus() {
      this.$confirm("确定要改变所选状态吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const ids = this.selectList.map((item) => item.id).join(",");
          changeKeyWordStatus(ids, this.needToChangeStatus)
            .then(() => {
              this.$message.success("修改成功!");
            })
            .finally(() => {
              this.initData();
              setTimeout(() => {
                this.needToChangeStatus = null;
              }, 3000);
            });
        })
        .catch(() => {});
    },
    resetData() {},
  },
  components: {
    AddKeyWord,
    keyWordDetail,
  },
};
</script>
<style lang='scss' scoped>
.key-word-container {
  background: #fff;
}
.button-list {
  text-align: center;
  margin-top: 50px;
}
</style>
