<template>
  <!-- <el-dialog
      title="选择素材"
      :visible.sync="show"
      width="800px"
      top="5vh"
      append-to-body
      :before-close="handleClose"> -->
  <div>
    <el-table
      :data="dataList"

      max-height="450px"
      v-loading="loading"
      highlight-current-row
      @current-change="(row) => (this.current = row)"
      style="width: 100%; margin: 20px 0"
    >
      <el-table-column prop="select" label="选择" width="50">
        <template slot-scope="scope">
          <el-radio
            type="radio"
            :value="current ? current.mediaId : null"
            :label="scope.row.mediaId"
          ></el-radio>
        </template>
      </el-table-column>
      <el-table-column property="url" align="center" label="图片" width="100">
        <template slot-scope="scope">
          <img
            :src="scope.row.url"
            alt=""
            class="book-img"
            @mouseenter="showImgDetail($event, scope.row.url)"
            @mouseleave="hideImgDetail()"
          />
        </template>
      </el-table-column>
      <el-table-column property="title" label="标题"> </el-table-column>
      <img id="imgDetail" v-show="bigImg.isShow" :src="bigImg.src" alt="" />
    </el-table>
    <el-pagination
      style="text-align: right"
      background
      @current-change="handlePageChange"
      :current-page="page"
      :page-size="20"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <div class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSure">确 定</el-button>
    </div>
  </div>
</template>

<script>
import { getMaterial } from '../../../api/service'

export default {
  name: 'selectTitle',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    accountId: Number
  },
  data () {
    return {
      title: null,
      dataList: [],
      page: 1,
      total: 0,
      loading: false,
      current: {},
      bigImg: {
        src: '',
        isShow: false
      }
    }
  },
  watch: {
    show: {
      handler (newVal) {
        if (newVal) {
          this.handlePageChange(1)
        }
      },
      immediate: true
    }
  },
  methods: {
    // 图片放大到原图大小
    showImgDetail (event, src) {
      this.bigImg.src = src
      this.bigImg.isShow = true
      const img = document.getElementById('imgDetail')
      // const targetX = 20 + event.clientX - img.offsetWidth / 2;
      // const targetY = 20 + event.clientY - img.offsetHeight / 2;
      const targetX = 50 + event.clientX
      const targetY = document.body.clientHeight / 2 - 100
      img.style.left = targetX + 'px'
      img.style.top = targetY + 'px'
    },
    hideImgDetail () {
      this.bigImg.isShow = false
    },
    handleSure () {
      this.$emit('success', this.current)
      this.handleClose()
    },
    handlePageChange (page) {
      this.page = page
      this.getList()
    },
    getList () {
      this.loading = true
      getMaterial(this.accountId, {
        page: this.page,
        pageSize: 20,
        type: 'image'
      })
        .then((res) => {
          this.dataList = res.list
          this.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleClose () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.input-with-select {
  width: 200px;
  margin-right: 20px;
}
.dialog-footer {
  margin-top: 40px;
  text-align: center;
}
.book-img {
  height: 50px;
  width: auto;
}
#imgDetail {
  position: absolute;
  z-index: 999999;
  max-width: 500px;
  left: 0;
  top: 0;
}
</style>
