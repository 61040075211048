<template>
  <!-- <el-dialog
      title="选择素材"
      :visible.sync="show"
      width="800px"
      top="5vh"
      append-to-body
      :before-close="handleClose"> -->
  <div>
    <el-table
      :data="dataList"

      max-height="450px"
      v-loading="loading"
      highlight-current-row
      @current-change="row => (this.current = row)"
      style="width: 100%;margin: 20px 0;"
    >
      <el-table-column prop="select" label="选择" width="50">
        <template slot-scope="scope">
          <el-radio
            type="radio"
            :value="current ? current.mediaId : null"
            :label="scope.row.mediaId"
          ></el-radio>
        </template>
      </el-table-column>
      <el-table-column property="title" label="标题"> </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: right"
      background
      @current-change="handlePageChange"
      :current-page="page"
      :page-size="20"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <div class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSure">确 定</el-button>
    </div>
  </div>
</template>

<script>
import { getMaterial } from '../../../api/service'

export default {
  name: 'selectTitle',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    accountId: Number,
    type: String
  },
  data () {
    return {
      title: null,
      dataList: [],
      page: 1,
      total: 0,
      loading: false,
      current: {}
    }
  },
  watch: {
    show: {
      handler (newVal) {
        if (newVal) {
          this.handlePageChange(1)
        }
      },
      immediate: true
    }
  },
  methods: {
    handleSure () {
      this.$emit('success', this.current)
      this.handleClose()
    },
    handlePageChange (page) {
      this.page = page
      this.getList()
    },
    getList () {
      this.loading = true
      getMaterial(this.accountId, {
        page: this.page,
        pageSize: 20,
        type: 'voice'
      })
        .then(res => {
          this.dataList = res.list
          this.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleClose () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.input-with-select {
  width: 200px;
  margin-right: 20px;
}
.dialog-footer {
  margin-top: 40px;
  text-align: center;
}
</style>
